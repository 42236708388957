import axios, { AxiosResponse } from 'axios'
interface IDealerGroupData {
  dealerGroupNumber: string
  dealerLogoPath: string | null
}
export const getClaimDetails = async (
  serviceOrderNumber: string | undefined,
  _token: string | null = null,
) => {
  if (!serviceOrderNumber) return null
  let token = _token
  if (!token) {
    token = localStorage.getItem('token')
  }
  if (token) {
    const url =
      process.env.REACT_APP_CLAIM_SERVICE_API_ENDPOINT +
      '/claim?serviceOrderNumber=' +
      serviceOrderNumber

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
    const detailsRequest = await axios.get(url, { headers: headers })
    if (detailsRequest?.data) return detailsRequest?.data
    else return null
  } else {
    return null
  }
}

export const getClaimListByDealerNumber = async (
  dealerNumber: IDealerGroupData[] | null,
  _token: string | null = null,
) => {
  if (!dealerNumber) return null
  let token = _token
  if (!token) {
    token = localStorage.getItem('token')
  }
  if (token) {
    const url = `${process.env.REACT_APP_CLAIM_SERVICE_API_ENDPOINT}/claim/list?dealerNumber=${JSON.stringify(dealerNumber)}`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
    const detailsRequest = await axios.get(url, { headers: headers })
    if (detailsRequest?.data) return detailsRequest?.data
    else return null
  }
}

export const getCrmKey = async (
  serviceOrderNumber: string | undefined,
  _token: string | null = null,
): Promise<string | null> => {
  try {
    let token = _token
    if (!token) {
      token = localStorage.getItem('token')
    }
    if (!token) throw new Error('Token not found')
    const url = `${process.env.REACT_APP_OPW_SERVICE_API_ENDPOINT}/appointment/crm/key`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
    const response: AxiosResponse<{ status: number; data: string; message: string }> =
      await axios.post(url, { serviceOrderNumber }, { headers })
    return response.data.data
  } catch (error) {
    console.error('Error getting crm key API:', error)
    return null
  }
}
