import React, { useContext } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import AuthContext from '../../contexts/AuthContext'
import { logout } from '../../services/opw.api'

const styles = {
  avatarCircle: {
    backgroundColor: '#ff5722',
    width: '38px',
    height: '38px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarName: {
    color: 'white',
  },
}

const UserAvatar = () => {
  const authContext = useContext(AuthContext)

  const handleLogOut = async () => {
    await logout(authContext?.data?.token)
    authContext?.updateData('token', '')
    authContext?.updateData('user', null)
    window.location.href = '/'
  }

  const userFullName = () => {
    let name = ''
    if (authContext?.data?.user?.firstName) name = authContext?.data?.user?.firstName
    if (authContext?.data?.user?.lastName) name += ' ' + authContext?.data?.user?.lastName
    return name
  }

  const renderAvatar = () => {
    let name = ''
    if (authContext?.data?.user?.firstName)
      name = authContext?.data?.user?.firstName[0].toUpperCase()
    if (authContext?.data?.user?.lastName)
      name += authContext?.data?.user?.lastName[0].toUpperCase()
    return (
      <div style={styles.avatarCircle}>
        <span style={styles.avatarName}>{name}</span>
      </div>
    )
  }

  if (!authContext?.data?.user) return null

  return (
    <React.Fragment>
      {renderAvatar()}
      <Dropdown>
        <Dropdown.Toggle
          className='btn'
          style={{ backgroundColor: 'white', border: 'none', color: 'black' }}
        >
          <div className='d-none d-sm-inline-block'>{userFullName()}</div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item className='d-sm-none'>{userFullName()}</Dropdown.Item>
          <Dropdown.Item onClick={handleLogOut}>Log Out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  )
}

export default UserAvatar
