// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download_font {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.9rem;
}

.font_normal {
  font-size: 1.5rem;
  font-weight: 900 !important;
}

.btn_style {
  background-color: #009abe;
  border-color: #009abe;
}

.btn_style:active {
  background-color: #009abe !important;
  border-color: #009abe !important;
}

.btn_style:hover {
  background-color: #007799;
  border-color: #007799;
}

.btn_style_reset {
  background-color: transparent;
  border-color: gray;
  color: gray;
  margin-left: 1rem;
}

.btn_style_reset:active {
  background-color: transparent !important;
  border-color: rgb(195, 204, 209) !important;
  color: gray !important;
}

.btn_style_reset:hover {
  background-color: rgb(195, 204, 209);
  border-color: rgb(195, 204, 209);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Sales/SalesUpload/index.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0BAA0B;EAC1B,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,oCAAoC;EACpC,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,wCAAwC;EACxC,2CAA2C;EAC3C,sBAAsB;AACxB;;AAEA;EACE,oCAAoC;EACpC,gCAAgC;AAClC","sourcesContent":[".download_font {\n  color: #007bff;\n  text-decoration: underline;\n  cursor: pointer;\n  font-size: 0.9rem;\n}\n\n.font_normal {\n  font-size: 1.5rem;\n  font-weight: 900 !important;\n}\n\n.btn_style {\n  background-color: #009abe;\n  border-color: #009abe;\n}\n\n.btn_style:active {\n  background-color: #009abe !important;\n  border-color: #009abe !important;\n}\n\n.btn_style:hover {\n  background-color: #007799;\n  border-color: #007799;\n}\n\n.btn_style_reset {\n  background-color: transparent;\n  border-color: gray;\n  color: gray;\n  margin-left: 1rem;\n}\n\n.btn_style_reset:active {\n  background-color: transparent !important;\n  border-color: rgb(195, 204, 209) !important;\n  color: gray !important;\n}\n\n.btn_style_reset:hover {\n  background-color: rgb(195, 204, 209);\n  border-color: rgb(195, 204, 209);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
