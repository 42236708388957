import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import React, { ReactNode } from 'react'
import { msalConfig } from '../../config/msalConfig'

interface Props {
  children: ReactNode
}

const msalInstance = new PublicClientApplication(msalConfig)
// const electroluxMsalInstance = new PublicClientApplication(electroluxMsalConfig)

const MsalProviderCustom = ({ children }: Props) => {
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}
export default MsalProviderCustom
