// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claim-status {
  width: 20px;
  height: 52px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(180deg, #4fd3f2 0%, #009abe 98.96%);
  border-right: 1px solid #ffffff4d;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 102px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/claim/details/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,+DAA+D;EAC/D,iCAAiC;EACjC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".claim-status {\n  width: 20px;\n  height: 52px;\n  color: white;\n  font-size: 14px;\n  font-weight: bold;\n  text-align: center;\n  background: linear-gradient(180deg, #4fd3f2 0%, #009abe 98.96%);\n  border-right: 1px solid #ffffff4d;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-width: 102px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
