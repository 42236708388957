import axios, { AxiosResponse } from 'axios'

interface CancelData {
  serviceAvengerContractNumber: string
  externalContractNumber: string
  serviceOrderNumber: string
  crmKey: string
  firstName: string
  lastName: string
  consumerPhone: string
  productSeqeuenceNumber: string
  servicerID: string
  appointmentDate: string
  appointmentTimeSlot: string
  serviceType: string
  serviceLocation: string
  servicerName: string
  servicerPhone: string
  servicerWebsite: string
  cancelReason: string
}

export const getAppointment = async (
  serviceOrderNumber: string | undefined,
  serviceAvengerContractNumber: string | undefined,
  _token: string | null = null,
) => {
  if (!serviceOrderNumber || !serviceAvengerContractNumber) return null
  let token = _token
  if (!token) {
    token = localStorage.getItem('token')
  }
  if (token) {
    const url = `${process.env.REACT_APP_CLAIM_SERVICE_API_ENDPOINT}/appointments?serviceOrderNumber=${serviceOrderNumber}&serviceAvengerContractNumber=${serviceAvengerContractNumber}`

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
    const detailsRequest = await axios.get(url, { headers: headers })
    if (detailsRequest?.data) return detailsRequest?.data
    else return null
  } else {
    return null
  }
}

export const cancelAppointment = async (cancelData: CancelData): Promise<string | null> => {
  try {
    const url = `${process.env.REACT_APP_SA_API_ENDPOINT}/standard/cancel/serviceappointment`

    const headers = {
      'Content-Type': 'application/json',
      Authorization: process.env.REACT_APP_DEVELOPMENT_API_KEY,
    }
    const response: AxiosResponse<{ status: number; data: string; message: string }> =
      await axios.post(url, cancelData, { headers })
    return response.data.message
  } catch (error) {
    console.error('Error cancelling service appointment:', error)
    return null
  }
}
