import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
// import getDealerNumber from '../../../services/dealerNumberSearch.api'
import { getClaimListByDealerNumber } from '../../../services/claim.api'
import AuthContext from '../../../contexts/AuthContext'
import {
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Card,
  Col,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap'
import CustomPagination from '../../../Components/Pagination/Pagination'
import glassIcon from '../../../Assets/Icons/magnifying-lens.png'
import debounce from 'lodash/debounce'
import './index.css'

const PAGE_SIZE_OPTIONS = [10, 20, 50] // Define available page size options

const initialTableHeadings: string[] = [
  '',
  'Claim Number',
  'Contract Number',
  'Customer Name',
  'Claim Date',
]

const initialTableHeadingsWithDLNum: string[] = [
  '',
  'Dealer Group Number',
  'Claim Number',
  'Contract Number',
  'Customer Name',
  'Claim Date',
]

interface Claims {
  claimDate: string
  claimNumber: string
  contactFirstName: string
  contactLastName: string
  contractNumber: string
}

interface ClaimsList {
  data: Claims[]
  totalCount: number
}

const ClaimListTable = () => {
  const [loading, setLoading] = useState(false)
  const authDetails = useContext(AuthContext)
  const tokenvalue = authDetails.data.token
  const [tableHeadings, setTableHeadings] = useState<string[]>(initialTableHeadings)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [claimsListDetail, setClaimsListDetail] = useState<ClaimsList | null>(null)
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    if (authDetails.data.user?.dealerGroupData.length === 1) {
      setTableHeadings(initialTableHeadingsWithDLNum)
    } else {
      setTableHeadings(initialTableHeadings)
    }
  }, [authDetails])
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  // const [sortBy, setSortBy] = useState<string | null>(searchParams.get('sortBy')) // Track sorting column
  const [sortBy] = useState<string | null>(searchParams.get('sortBy')) // Track sorting column

  const [sortOrder, setSortOrder] = useState<string | null>(searchParams.get('sortOrder')) // Track sorting order

  // Debounce search function
  const handleSearch = debounce((query: string) => {
    setSearchQuery(query)
  }, 300)

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  // Handle page size change
  const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPageSize(Number(event.target.value))
    setPage(1) // Reset page to 1 when changing page size
  }

  const sortedData = useMemo(() => {
    if (!claimsListDetail?.data.length) return []
    if (!sortBy) return claimsListDetail.data
    const sortedList = [...claimsListDetail.data].sort((a, b) => {
      if (sortBy !== 'name') {
        const keyA = (a[sortBy as keyof Claims] as string).toLowerCase() // Type assertion to keyof Claims
        const keyB = (b[sortBy as keyof Claims] as string).toLowerCase() // Type assertion to keyof Claims
        if (keyA < keyB) return sortOrder === 'asc' ? -1 : 1
        if (keyA > keyB) return sortOrder === 'asc' ? 1 : -1
        return 0
      } else {
        const keyA = a['contactFirstName'].toLowerCase() // Type assertion to keyof Claims
        const keyB = b['contactFirstName'].toLowerCase() // Type assertion to keyof Claims
        if (keyA < keyB) return sortOrder === 'asc' ? -1 : 1
        if (keyA > keyB) return sortOrder === 'asc' ? 1 : -1
        return 0
      }
    })

    return sortedList
  }, [claimsListDetail, sortBy, sortOrder])

  const filteredData = useMemo(() => {
    if (!claimsListDetail) return []
    if (!sortedData) return []
    const startIndex = (page - 1) * pageSize
    const endIndex = Math.min(startIndex + pageSize, claimsListDetail?.totalCount)

    const filtered = sortedData.filter((claim) => {
      const searchLowerCase = searchQuery.toLowerCase()
      return (
        claim.claimNumber.toLowerCase().includes(searchLowerCase) ||
        claim.claimDate.toLowerCase().includes(searchLowerCase) ||
        `${claim.contactFirstName} ${claim.contactLastName}`
          .toLowerCase()
          .includes(searchLowerCase) ||
        claim.contractNumber.toLowerCase().includes(searchLowerCase)
      )
    })

    setTotalPages(Math.ceil((filtered?.length || 0) / pageSize))

    return filtered.slice(startIndex, endIndex)
  }, [claimsListDetail, sortedData, page, pageSize, searchQuery])
  const init = useCallback(async () => {
    setLoading(true)
    try {
      const dealerNumber = authDetails.data.user ? authDetails.data.user.dealerGroupData : null

      if (dealerNumber) {
        const claimListDetailRes = await getClaimListByDealerNumber(dealerNumber, tokenvalue)
        setClaimsListDetail({
          data: claimListDetailRes.data,
          totalCount: claimListDetailRes.totalCount,
        })
      } else {
        setClaimsListDetail(null)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [authDetails.data.user])

  // const updateSorting = (sortByValue: string) => {
  //   setPage(1)
  //   if (sortByValue == sortBy) {
  //     sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc')
  //     return
  //   }

  //   setSortBy(sortByValue)
  //   setSortOrder('desc')
  // }

  useEffect(() => {
    init()
  }, [])

  return loading ? (
    <div className='d-flex justify-content-center align-items-center h-100'>
      <Spinner animation='border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    </div>
  ) : (
    <Fragment>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <h6 className='fw-normal fs-4 m-0'>Submitted Requests</h6>
        <div className='ms-auto'>
          <div className='input-group w-auto overflow-hidden flex-grow-1 flex-sm-grow-0 d-flex bg-white border border-2'>
            <span
              className='input-group-text d-flex justify-content-center align-items-center border-0 p-0'
              style={{ backgroundColor: 'inherit' }}
            >
              <img
                src={glassIcon}
                height={20}
                className='mx-3 my-2'
                style={{ filter: 'brightness(0.2)' }}
              />
            </span>
            <input
              name='search-table'
              type='text'
              className='form-control border-0'
              aria-label='header search input'
              onChange={(e) => handleSearch(e.target.value)}
              placeholder='Search Text'
            />
          </div>
        </div>
      </div>

      <div className='table-responsive my-3'>
        <div className='d-none d-sm-block'>
          <Table striped className='my-0 warranty-details-table'>
            <thead className='thead-dark'>
              <tr className='border-0'>
                {tableHeadings.map((heading, idx) => {
                  return (
                    <th
                      key={idx}
                      scope='col'
                      className='border-0 fw-normal'
                      style={{ textWrap: 'nowrap' }}
                    >
                      {heading}
                    </th>
                  )
                })}
              </tr>
            </thead>
            {claimsListDetail && claimsListDetail?.data ? (
              <tbody>
                {filteredData &&
                  filteredData.map((claim) => {
                    // Function to highlight the matching part of the text
                    const highlightMatch = (text: string) => {
                      const searchLowerCase = searchQuery.toLowerCase()
                      const index = text.toLowerCase().indexOf(searchLowerCase)
                      if (index === -1) return text // If no match, return original text
                      const beforeMatch = text.substring(0, index)
                      const match = text.substring(index, index + searchQuery.length)
                      const afterMatch = text.substring(index + searchQuery.length)
                      return (
                        <span>
                          {beforeMatch}
                          <span style={{ backgroundColor: 'yellow' }}>{match}</span>
                          {afterMatch}
                        </span>
                      )
                    }

                    return (
                      <tr key={claim.contractNumber + claim.claimNumber}>
                        <td className='text-secondary ps-3'>
                          <Link
                            className='btn btn-primary btn-sm'
                            to={`/claim/${claim.claimNumber}`}
                          >
                            View Details
                          </Link>
                        </td>
                        {authDetails?.data.user?.dealerGroupData.length === 1 && (
                          <td className='text-secondary'>
                            {highlightMatch(
                              authDetails?.data?.user?.dealerGroupData[0].dealerGroupNumber,
                            )}
                          </td>
                        )}
                        <td className='text-secondary'>{highlightMatch(claim.claimNumber)}</td>
                        <td className='text-secondary'>{highlightMatch(claim.contractNumber)}</td>
                        <td className='text-secondary'>
                          {highlightMatch(`${claim.contactFirstName} ${claim.contactLastName}`)}
                        </td>
                        <td className='text-secondary'>
                          {highlightMatch(
                            new Date(claim.claimDate).toLocaleDateString('en-US', {
                              month: '2-digit',
                              day: '2-digit',
                              year: 'numeric',
                            }),
                          )}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={tableHeadings.length}>There are no claims to display.</td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
        <div className='d-sm-none table-container'>
          <div className='d-flex justify-content-between mb-2'>
            {/* <DropdownButton as={ButtonGroup} variant={'secondary'.toLowerCase()} title={'Sort By'}>
              {TABLE_HEADINGS.map((headingObj, idx) => {
                return (
                  <Dropdown.Item
                    key={headingObj.sortByName + idx}
                    eventKey={idx + 1}
                    active={sortBy?.trim().toLowerCase() === headingObj.sortByName}
                    onClick={() => setSortBy(headingObj.sortByName)}
                  >
                    {headingObj.heading.trim().length ? headingObj.heading : 'None'}
                  </Dropdown.Item>
                )
              })}
            </DropdownButton> */}
            <DropdownButton
              as={ButtonGroup}
              variant={'secondary'.toLowerCase()}
              title={'Sort Order'}
            >
              <Dropdown.Item
                eventKey='1'
                active={sortOrder?.trim().toLowerCase() === 'asc'}
                onClick={() => setSortOrder('asc')}
              >
                ASC
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='2'
                active={sortOrder?.trim().toLowerCase() === 'desc'}
                onClick={() => setSortOrder('desc')}
              >
                DESC
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item eventKey='4'>Separated link</Dropdown.Item>
            </DropdownButton>
          </div>
          {claimsListDetail && claimsListDetail?.totalCount > 0 ? (
            <Fragment>
              {filteredData &&
                filteredData.map((claim) => {
                  // Function to highlight the matching part of the text
                  const highlightMatch = (text: string) => {
                    const searchLowerCase = searchQuery.toLowerCase()
                    const index = text.toLowerCase().indexOf(searchLowerCase)
                    if (index === -1) return text // If no match, return original text
                    const beforeMatch = text.substring(0, index)
                    const match = text.substring(index, index + searchQuery.length)
                    const afterMatch = text.substring(index + searchQuery.length)
                    return (
                      <span>
                        {beforeMatch}
                        <span style={{ backgroundColor: 'yellow' }}>{match}</span>
                        {afterMatch}
                      </span>
                    )
                  }

                  return (
                    <Card key={claim.claimNumber} className='mb-1'>
                      <Card.Header as='h5' className='warranty-details text-white'>
                        <Row>
                          <Col className='text-end fw-bold' xs={3}>
                            Claim:
                          </Col>
                          <Col xs={9}>{highlightMatch(claim.claimNumber)}</Col>
                        </Row>
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                          <Row>
                            <Col className='text-end fw-bold' xs={3}>
                              Contract:
                            </Col>
                            <Col xs={9}>{highlightMatch(claim.contractNumber)}</Col>
                          </Row>
                          <Row>
                            <Col className='text-end fw-bold' xs={3}>
                              Customer:
                            </Col>
                            <Col xs={9}>
                              {highlightMatch(`${claim.contactFirstName} ${claim.contactLastName}`)}
                            </Col>
                          </Row>
                          <Row>
                            <Col className='text-end fw-bold' xs={3}>
                              Filed:
                            </Col>
                            <Col xs={9}>
                              {highlightMatch(
                                new Date(claim.claimDate).toLocaleDateString('en-US', {
                                  month: '2-digit',
                                  day: '2-digit',
                                  year: 'numeric',
                                }),
                              )}
                            </Col>
                          </Row>
                        </Card.Text>
                        <Row>
                          <Col className='text-center'>
                            <Link
                              className='btn btn-primary btn-sm'
                              to={`/claim/${claim.claimNumber}`}
                            >
                              View
                            </Link>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  )
                })}
            </Fragment>
          ) : (
            <Fragment>
              <h6 className='fs-4 text-secondary'>There are no claims to display.</h6>
            </Fragment>
          )}
        </div>
        <div className='d-flex align-items-center justify-content-between mx-2 my-3 pagination-container'>
          <div className='flex-grow-1 per-page'>
            <select
              name='table-length-select'
              className='form-select w-auto'
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              {PAGE_SIZE_OPTIONS.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
          <CustomPagination
            totalPages={totalPages}
            currentPage={page}
            handleChangePage={handlePageChange}
          />
        </div>
      </div>
    </Fragment>
  )
}
export default ClaimListTable
