import React, { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Layout } from './Components/Layout'
import { NavigationContextProvider } from './contexts/NavigationContextProvider'
import NotFound from './pages/404/NotFound'
import Login from './pages/Auth/login'

// Lazy load components
const DashboardContent = lazy(() => import('./Components/DashboardContent'))
const DashboardScreensLayout = lazy(() => import('./Components/DashboardScreensLayout'))
const ClaimListTable = lazy(() => import('./pages/claim/ClaimListTable'))
const ClaimScreenForDashboard = lazy(() => import('./pages/claim/ClaimScreenForDashboard'))
const ClaimDetail = lazy(() => import('./pages/claim/details'))
const SalesDetail = lazy(() => import('./pages/Sales/SalesScreenForDashboard'))
const Upload = lazy(() => import('./pages/Sales/SalesUpload'))
const ViewSales = lazy(() => import('./pages/Sales/ViewSales'))

function App() {
  return (
    <div className='App' style={{ height: '100vh', overflow: 'hidden' }}>
      <NavigationContextProvider>
        <Layout>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path='/'>
                <Route path='' element={<DashboardContent />} />
                <Route element={<DashboardScreensLayout />}>
                  <Route path='claim'>
                    <Route path='' element={<ClaimScreenForDashboard />} />
                    <Route path='list' element={<ClaimListTable />} />
                    <Route path=':id' element={<ClaimDetail />} />
                  </Route>
                  <Route path='sales'>
                    <Route path='' element={<SalesDetail />} />
                    <Route path='upload' element={<Upload />} />
                    <Route path='viewSales' element={<ViewSales />} />
                  </Route>
                </Route>
                <Route path='*' element={<NotFound />} />
              </Route>
              <Route path='/login' element={<Login />} />
            </Routes>
          </Suspense>
        </Layout>
      </NavigationContextProvider>
    </div>
  )
}

export default App
