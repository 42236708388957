import React, { useContext, useState } from 'react'
import { Image, Navbar, Offcanvas } from 'react-bootstrap'
import MainLogo from '../../Assets/Images/ServiceAvengerLogo.png'
import LoginSideBar from './LoginSideBar'
import UserAvatar from './UserAvatar'
import AuthContext from '../../contexts/AuthContext'

const styles = {
  logoStyle: {
    maxHeight: '40px',
    width: '170px',
    marginRight: '10px',
  },
}

export default function LoginHeader() {
  const [show, setShow] = useState(false)
  const authDetails = useContext(AuthContext)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <header
      className='px-2 px-sm-3 px-lg-5 py-2 bg-white'
      style={{ height: '53px', borderBottom: '1px solid #EEEEEE' }}
    >
      <div className='d-flex justify-content-between h-100'>
        <div className='d-flex'>
          <Navbar collapseOnSelect expand='lg' className='p-0' bg='white' data-bs-theme='white'>
            <Navbar.Toggle onClick={handleShow} />
            <Offcanvas show={show} onHide={handleClose}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  <Image src={MainLogo} alt='Logo' height={37} />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className='overflow-scroll'>
                <LoginSideBar />
              </Offcanvas.Body>
            </Offcanvas>
          </Navbar>
          <Image src={MainLogo} alt='Logo' className='h-100' />
        </div>
        <div className='d-flex align-items-center flex-shrink-1'>
          {authDetails?.data.user?.dealerGroupData.length === 1 && (
            <div className='d-flex align-items-center flex-shrink-1'>
              <Image
                src={authDetails?.data.user?.dealerGroupData[0]?.dealerLogoPath ?? ''}
                alt='Dealer Logo'
                style={styles.logoStyle}
              />
            </div>
          )}

          <div className='d-flex align-items-center flex-shrink-1'>
            <UserAvatar />
          </div>
        </div>
      </div>
    </header>
  )
}
