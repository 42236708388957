// NavigationContextProvider.tsx
import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import dashboardIcon from '../Assets/Icons/Dashboard-Icons/house-insurance.png'
import paymentsIcon from '../Assets/Icons/Dashboard-Icons/payments-icon.png'
import techSupportIcon from '../Assets/Icons/Dashboard-Icons/technical-support.png'
import userManagementIcon from '../Assets/Icons/Dashboard-Icons/user-management.png'
import claimsIcon from '../Assets/Icons/Dashboard-Icons/warranty-claims.png'
import marketingIcon from '../Assets/Icons/Dashboard-Icons/warranty-marketing.png'
import reportingIcon from '../Assets/Icons/Dashboard-Icons/warranty-reporting.png'
import salesIcon from '../Assets/Icons/Dashboard-Icons/warranty-sales.png'
import trainingIcon from '../Assets/Icons/Dashboard-Icons/warranty-training.png'

// Define context
interface NavigationContextType {
  breadcrumbs: Breadcrumb[]
  updateBreadcrumbs: (newBreadcrumbs: Breadcrumb[]) => void
  menuItems: MenuItem[] // Include MenuItems here
}

const NavigationContext = createContext<NavigationContextType>({
  breadcrumbs: [],
  updateBreadcrumbs: () => {},
  menuItems: [], // Initialize with empty array
})

// Define Breadcrumb interface
interface Breadcrumb {
  label: string
  path: string
}

// Define MenuItem interface
interface MenuItem {
  name: string
  link: string
  icon: string
  active: boolean
}

interface NavigationContextProviderProps {
  children: ReactNode
}

// Breadcrumbs context provider component
export const NavigationContextProvider: React.FC<NavigationContextProviderProps> = ({
  children,
}) => {
  const location = useLocation()
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])
  const menuItems: MenuItem[] = useMemo(() => {
    return [
      {
        name: 'Dashboard',
        link: '/',
        icon: dashboardIcon,
        active: !location.pathname.trim().split('/')[1],
      },
      {
        name: 'payments, chargebacks & credits',
        link: '/payments',
        icon: paymentsIcon,
        active: location.pathname.includes('payments'),
      },
      {
        name: 'technical support',
        link: '/technicalsupport',
        icon: techSupportIcon,
        active: location.pathname.includes('technicalsupport'),
      },
      {
        name: 'user management',
        link: '/usermanagement',
        icon: userManagementIcon,
        active: location.pathname.includes('usermanagement'),
      },
      {
        name: 'Service Order',
        link: '/claim',
        icon: claimsIcon,
        active: location.pathname.includes('claim'),
      },
      {
        name: 'Warranty marketing support',
        link: '/w-marketingsupport',
        icon: marketingIcon,
        active: location.pathname.includes('w-marketingsupport'),
      },
      {
        name: 'Warranty reporting',
        link: '/w-reporting',
        icon: reportingIcon,
        active: location.pathname.includes('w-reporting'),
      },
      {
        name: 'Warranty sales',
        link: '/w-sales',
        icon: salesIcon,
        active: location.pathname.includes('w-sales'),
      },
      {
        name: 'Warranty training support',
        link: '/w-trainingsupport',
        icon: trainingIcon,
        active: location.pathname.includes('w-trainingsupport'),
      },
    ]
  }, [location.pathname])

  const updateBreadcrumbs = (newBreadcrumbs: Breadcrumb[]) => {
    setBreadcrumbs(newBreadcrumbs)
  }

  const value = {
    breadcrumbs,
    updateBreadcrumbs,
    menuItems,
  }

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>
}

// Custom hook to consume the NavigationContext
export const useNavigationContext = () => useContext(NavigationContext)
