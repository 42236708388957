/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AuthContext from '../../../contexts/AuthContext'
import { getAppointment, cancelAppointment } from '../../../services/appointments.api'
import { getClaimDetails, getCrmKey } from '../../../services/claim.api'
import { Trash3 } from 'react-bootstrap-icons'
import './index.css'
import { Button, Col, Container, Image, Modal, Row, Spinner } from 'react-bootstrap'
import { ToastOptions, toast } from 'react-toastify'
import FlightPathLogo from '../../../Assets/Icons/flightPath.jpg'

const ClaimDetail = () => {
  const { id } = useParams()
  const [claim, setClaim] = useState<any>(null)
  const [appointmentData, setAppointmentData] = useState<any>(null)
  const [crmKey, setcrmKey] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)
  const authctx = useContext(AuthContext)

  function formatPhoneNumber(phoneNumber: string) {
    if (!phoneNumber) return ''
    const cleaned = phoneNumber.replace(/\D/g, '')
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    return formatted
  }

  const getAptDate = () => {
    const appointmentDate = appointmentData?.appointmentDate
    if (!appointmentDate) return ''
    const date: Date = new Date(appointmentDate)
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    }
    const formattedDate: string = date.toLocaleDateString('en-US', options)
    const formattedDateTime: string = `${formattedDate}   ${appointmentData?.appointmentTimeSlot}`
    return formattedDateTime
  }

  const init = useCallback(async () => {
    setLoading(true)
    try {
      const token = authctx.data.token
      const data = await getClaimDetails(id, token)
      const aptData = await getAppointment(id, data?.data?.claim.contractNumber, token)
      const crmkeyData = await getCrmKey(id, token)
      setcrmKey(crmkeyData)
      setClaim(data?.data.claim)
      setAppointmentData(aptData?.data?.length > 0 ? aptData?.data[0] : null)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [setLoading, setClaim, id])

  useEffect(() => {
    init()
  }, [init])

  const handleCancelJob = async () => {
    setIsCancelling(true)
    try {
      interface CancelData {
        serviceAvengerContractNumber: string
        externalContractNumber: string
        serviceOrderNumber: string
        crmKey: string
        firstName: string
        lastName: string
        consumerPhone: string
        productSeqeuenceNumber: string
        servicerID: string
        appointmentDate: string
        appointmentTimeSlot: string
        serviceType: string
        serviceLocation: string
        servicerName: string
        servicerPhone: string
        servicerWebsite: string
        cancelReason: string
      }

      const cancelData: CancelData = {
        serviceAvengerContractNumber: appointmentData?.serviceAvengerContractNumber,
        externalContractNumber: appointmentData?.externalContractNumber,
        serviceOrderNumber: appointmentData?.serviceOrderNumber,
        crmKey: crmKey.crmKey,
        firstName: appointmentData?.firstName,
        lastName: appointmentData?.lastName,
        consumerPhone: appointmentData?.consumerPhone,
        productSeqeuenceNumber: appointmentData?.productSeqeuenceNumber,
        servicerID: appointmentData?.servicerID,
        appointmentDate: appointmentData?.appointmentDate,
        appointmentTimeSlot: appointmentData?.appointmentTimeSlot,
        serviceType: appointmentData?.serviceType,
        serviceLocation: appointmentData?.serviceLocation,
        servicerName: appointmentData?.servicerName,
        servicerPhone: appointmentData?.servicerPhone,
        servicerWebsite: appointmentData?.servicerWebsite,
        cancelReason: 'CN01',
      }

      const toastConfig: ToastOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      }
      const response = await cancelAppointment(cancelData)

      if (response == 'Cancellation Request Was Successful!') {
        setShowModal(false)
        toast.success('Appointment cancelled successfully', toastConfig)
      } else {
        toast.error('Failed to cancel appointment', toastConfig)
      }
    } catch (error: any) {
      toast.error(error.message || 'Failed to cancel appointment')
    } finally {
      setIsCancelling(false)
    }
  }

  return (
    <React.Fragment>
      {loading ? (
        <div className='d-flex justify-content-center align-items-center h-100'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      ) : (
        claim && (
          <Container className='my-5 py-4 border rounded p-3 bg-white' style={{ minHeight: '80%' }}>
            <Row>
              <Col>
                <Image src={FlightPathLogo} alt='FlightPath' width={175} />
              </Col>
            </Row>
            <Row>
              <Col>
                <h6 className='fw-normal fs-4 mb-1'>Contract Number: {claim?.contractNumber}</h6>
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col>
                <Col className='d-flex justify-content-start mt-1 mb-1 overflow-x-auto'>
                  <Col className='claim-status'>Dispatched</Col>
                  <Col className='claim-status'>Accepted</Col>
                  <Col className='claim-status'>Appointment Confirmed</Col>
                  <Col className='claim-status'>Parts Ordered</Col>
                  <Col className='claim-status'>Enroute</Col>
                  <Col className='claim-status'>Arrived</Col>
                  <Col className='claim-status'>Rescheduled</Col>
                  <Col className='claim-status'>Claimed</Col>
                  <Col className='claim-status'>WoW Dispatched</Col>
                </Col>
              </Col>
            </Row>
            {/* <Row className='mt-3'>
              <Col>
                <Stack direction='horizontal' gap={3}>
                  <Button className='ms-auto' variant='light'>
                    <Calendar2Event size={15} className='me-1' />
                    <span>Reschedule</span>
                  </Button>
                  <Button
                    className='text-danger'
                    onClick={() => setShowModal(true)}
                    variant='light'
                  >
                    <Trash3 size={15} className='me-1' />
                    <span>Cancel</span>
                  </Button>
                </Stack>
              </Col>
            </Row> */}
            <Row className='mt-3'>
              <Col md={5}>
                <h6 className='fw-bold'>Claim Number:</h6>
                <h6 className='fw-normal'>{claim.claimNumber}</h6>
                <h6 className='fw-bold text-capitalize'>Consumer name:</h6>
                <h6 className='fw-light'>
                  {claim?.contact?.firstName} {claim?.contact?.lastName}
                </h6>
                <h6 className='fw-bold text-capitalize'>Service address:</h6>
                <h6 className='pe-4 fw-light' style={{ whiteSpace: 'break-spaces' }}>
                  {`${claim?.contact?.address?.address1}, ${claim?.contact?.address?.city}, ${claim?.contact?.address?.state} ${claim?.contact?.address?.postalCode}, ${claim?.contact?.address?.countryCode}`}
                </h6>
                <h6 className='fw-bold text-capitalize'>Phone:</h6>
                <h6 className='fw-light'>
                  {claim?.contact?.phone ? formatPhoneNumber(claim?.contact?.phone) : null}
                </h6>
                <h6 className='fw-bold text-capitalize'>Email:</h6>
                <h6 className='fw-light'>{claim?.contact?.email}</h6>
              </Col>

              <Col
                md={7}
                className='rounded px-2 px-sm-3 py-3'
                style={{ backgroundColor: 'rgb(239, 244, 245)' }}
              >
                <h6 className='fw-bold text-capitalize'>servicer name: </h6>
                <h6 className='fw-light'>{appointmentData?.servicerName}</h6>
                <h6 className='fw-bold text-capitalize'>appointment date: </h6>
                <h6 className='fw-light'>{getAptDate()}</h6>
                <h6 className='fw-bold text-capitalize'>product: </h6>
                {claim.product?.subCategory ? (
                  <h6 className='fw-light'>{claim?.product?.subCategory}</h6>
                ) : (
                  ''
                )}
                {!claim?.product?.mfg && !claim?.product?.modelName && !claim?.product?.serial
                  ? 'There are no product details to display'
                  : ''}
                {claim?.product?.mfg || claim?.product?.modelName ? (
                  <h6 className='fw-light'>
                    {claim?.product?.mfg + ' ' + claim?.product?.modelName}
                  </h6>
                ) : (
                  ''
                )}
                {claim?.product?.serial ? (
                  <h6 className='fw-light'>{claim?.product?.serial}</h6>
                ) : (
                  ''
                )}
                <h6 className='fw-bold text-capitalize'>problem description: </h6>
                {claim?.cause?.causeDescription ? (
                  <h6 className='fw-light'>{claim?.cause?.causeDescription}</h6>
                ) : (
                  ''
                )}
                {appointmentData?.failureNote ? (
                  <h6 className='fw-light'>{appointmentData?.failureNote}</h6>
                ) : (
                  ''
                )}
              </Col>
            </Row>

            <Modal show={showModal} centered backdrop='static' onHide={() => setShowModal(false)}>
              <Modal.Header className=' bg-danger'>
                <Modal.Title>
                  <h4 className='text-white p-2 mb-0'>Cancel Job</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Are you sure you want to cancel the scheduled job?</h5>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='light' onClick={() => setShowModal(false)} disabled={isCancelling}>
                  Close
                </Button>
                <Button variant='danger' onClick={handleCancelJob} disabled={isCancelling}>
                  {isCancelling ? (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                      className='me-2 mb-1'
                    />
                  ) : (
                    <Trash3 size={18} className='me-2 mb-1' />
                  )}
                  Cancel Job
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        )
      )}
    </React.Fragment>
  )
}

export default ClaimDetail
